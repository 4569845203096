import React, { useContext, useEffect, useState } from 'react';
import { Button, Text, Flex } from 'rebass';

import { patchMemoir } from '../api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import { useHistory, useMatch } from '../../app/hooks';
import FullPageForm from '../../app/full-page-form';
import OptionButton from '../../collections/new/gender/option-button';
import { isEmpty } from 'lodash';
import { CurrentModalContext } from 'app/modal/context';

const buttonProps = {
  bg: 'white',
  color: 'defaultText',
  mb: 3,
  type: 'button',
  fontFamily: 'quicksand',
  fontSize: 4,
  fontWeight: 'normal',
};

const MemoirSubjectStillWithUs = () => {
  const { setRevalidateSidebar } = useContext(CurrentModalContext);
  const { params } = useMatch();
  const { push } = useHistory();
  const { entity: memoir } = useEntity({ ...params });
  const { receive } = useEntityActionCreators();
  const [value, setValue] = useState(null);
  const [submitting, setSubmitting] = useState(null);

  const isMeSubject = memoir?.['subject-relation'] === 'me'
  const { 'subject-alive': subjectAlive } = memoir;
  useEffect(() => {
    setValue(subjectAlive);
  }, [subjectAlive]);


  const handleSubmit = async () => {
    if (!isMeSubject) {
      try {
        if (value !== subjectAlive) {
          setSubmitting(true);
          const body = {
            'subject-alive': value,
            'lock-version': memoir['lock-version'],
          };

          const memoirRes = await patchMemoir({ id: params.id, ...body });
          receive({ payload: memoirRes });
          setSubmitting(false);
        }
      }
      catch (err) {
        console.log(err)
      }
    }
    setRevalidateSidebar(true)
    push(`/memoirs/${params.id}/milestones`)
  };

  if (isEmpty(memoir))
    return null

  return (
    <FullPageForm
      onBackClick={() => push(`/memoirs/new/${params.id}/date-of-birth`)}
      title={isMeSubject ? '' : `Is ${memoir['subject-name']} still with us?`}
    >
      <Flex flexDirection="column" >
        {isMeSubject ? <Flex flexDirection={"column"} justifyContent="center" alignItems="center">
          <Text fontSize={25} mb={2}> Nice{' '}
            <span role="img" aria-label="heart">
              ❤️
            </span></Text>
          <Text fontSize={25}> Ready to rock and roll?</Text>
        </Flex> :
          <Flex flexDirection="column" pt={5}>
            <OptionButton
              {...buttonProps}
              disabled={submitting}
              selected={value === true}
              onClick={() => {
                setValue(true);
              }}
            >
              Yes{' '}
              <span role="img" aria-label="heart">
                ❤️
              </span>
            </OptionButton>
            <OptionButton
              {...buttonProps}
              disabled={submitting}
              selected={value === false}
              onClick={() => {
                setValue(false);
              }}
            >
              No{' '}
              <span role="img" aria-label="prayer hands">
                🙏
              </span>
            </OptionButton>
            <Text color="grey60" textAlign="center">
              (This information is used so we can give you more relevant prompts)
            </Text>
          </Flex>}
        <Button
          onClick={handleSubmit}
          variant="primary"
          disabled={submitting || (!isMeSubject && typeof value !== 'boolean')}
          mt={5}
          fontSize={20}
          style={{ padding: '16px' }}
        >
          {memoir?.paid ? "Next" : "Start your 7-day trial"}
        </Button>
      </Flex>
    </FullPageForm>
  );
};

export default MemoirSubjectStillWithUs;
