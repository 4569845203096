import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import store from 'store';
import { useDispatch } from 'react-redux';
import MemberOnboardLoading from 'members/complete-profile/onboarding-loading';
import { useGetMemberSWR } from '../members/api';
import Login from './login';
import { setAuth } from './actions';
import CompleteProfile from '../members/complete-profile';
import Loading from '../app/loading/index';
import { useEntity } from '../entities/hooks/index';
import { useCurrentMemberId } from '../members/hooks';
import { useCreateNewAccount } from './hooks';
// import authURL from '../utils/auth-url';

const AuthMember = ({ children }) => {
  const dispatch = useDispatch();
  const createNewUser = useCreateNewAccount();
  const { data, error, revalidate } = useGetMemberSWR('me', {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
  const { pathname } = useLocation();

  const isPublicRoute = pathname.includes('/tell_a_story')
  const currentMemberId = useCurrentMemberId();
  const { member } = useEntity({
    type: 'members',
    id: currentMemberId || '',
  });

  useEffect(() => {
    if (data) {
      const storeToken = store.get('auth_token');
      const { id: memberId } = data.data.data;
      const token = `Token ${storeToken}`;
      dispatch(setAuth({ token, memberId }));
    }
  }, [data, dispatch]);

  async function createNewUserRequest() {
    await createNewUser();
    revalidate()
  }

  useEffect(() => {
    if (isPublicRoute && !store.get('auth_token')) {
      createNewUserRequest()
    }
  }, [isPublicRoute])

  useEffect(() => {
    if (error) {
      store.set('postLoginRedirect', pathname);
    }
  }, [error, pathname, isPublicRoute]);

  if (error && !isPublicRoute) return <Login />;
  if (!currentMemberId) return <Loading />;
  if (!isPublicRoute) {
    if (!member['first-name'] || !member['last-name']) return <CompleteProfile />;

    if (!member.onboarded) return <MemberOnboardLoading />;
  }

  if (currentMemberId) return children;

  return false;
};
AuthMember.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthMember;
