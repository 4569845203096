import React from 'react';
import { Text, Box, Flex } from 'rebass';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { createMediaForMemoirRequestFiles } from 'story-media/actions';
import FileDropperField from 'stories/tell-a-story/FileDropperField';
import Footer from 'stories/tell-a-story/Footer';
import RecordVideo from 'stories/tell-a-story/RecordVideo';


const AddVideo = ({ storyRequestData, onSubmit, onStepDown, onStepUp, percentComplete }) => {
    const dispatch = useDispatch();

    const submitMedia = (files) => dispatch(createMediaForMemoirRequestFiles(storyRequestData?.id, files, onSubmit));

    const onSubmitMedia = files => submitMedia(files)

    return (
        <Flex flexDirection="column" m="auto" justifyContent="center" alignItems="left">
            <Flex p={2} flexDirection="column" justifyContent="center" alignItems="left">
                <Text
                    ml={2}
                    textAlign="left"
                    fontFamily='Zilla Slab'
                    fontSize={30}
                    lineHeight="120%"
                    letterSpacing='-0.66px'
                    mb={3}
                >
                    <i style={{ color: 'rgba(22, 22, 29, 0.70)' }}>(Optional)</i><br /> Add a video to expand on your question.
                </Text>
                <Text
                    ml={2}
                    mb={3}
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="120%"
                    letterSpacing="-0.352px"
                >
                    You can include a heartfelt request prompting specific details, request photos, and more.
                </Text>

                <RecordVideo question={storyRequestData?.question} label="Record Video" onSubmitMedia={onSubmitMedia} />

                <Formik initialValues={{}} onSubmit={({ file }) => submitMedia([file])}>
                    <Box mt={3}>
                        <FileDropperField
                            accept='video/*'
                            label="video"
                            name="file"
                            pb={2}
                            buttonText="Upload video"
                        />
                    </Box>
                </Formik>
                <Text
                    color="#766AB9"
                    fontFamily="DM Sans"
                    fontSize="16px"
                    letterSpacing="-0.352px"
                    lineHeight="140%"
                    textAlign="center"
                    sx={{ textDecorationLine: 'underline' }}
                >Watch example.</Text>
            </Flex>

            <Footer onBack={onStepDown} onNextPress={onStepUp} percentComplete={percentComplete} nextButtonText="Finish" />
        </Flex>
    );
};

export default AddVideo;
