import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from 'rebass';
import { FaVideo } from 'react-icons/fa';
import WebcamRecordVideo from './RecordVideo';
import Modal from 'app/modal/v3';
import VideoPreview from './VideoPreview';

const RecordChatButton = ({ onRecordChat, isFromRecordVideo, handleChange }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [startRecording, setStartRecording] = useState(false);
  const handleClick = async () => {
    if (isFromRecordVideo) {
      setStartRecording(true)
      return
    }
    setLoading(true);
    onRecordChat();
  };


  return (<>
    {startRecording && <Modal
      close={{ onClose: () => setStartRecording(false) }}
      maxWidth={800}
      title={"Record Your Video"}
      closeOnOutsideClick={false}
      aboveTooltip
    >
      {file ?
        <VideoPreview
          blob={file}
          onReject={() => setFile(undefined)}
          onAccept={() => {
            const newFile = new File([file], file.name, { type: file.type, lastModified: Date.now() });
            setStartRecording(false)
            handleChange({ target: { files: [newFile] } })
          }}
          minHeight={360}
          height="auto"
          width={['100vw', '640px', '800px']}
          mt={-80}
        />
        : <WebcamRecordVideo onEnded={(blob) => setFile(blob)} />}
    </Modal>}

    <Button
      fontWeight="normal"
      fontSize={2}
      flex={1}
      variant="muted"
      disabled={loading}
      onClick={handleClick}
      bg="#FFF9E6"
      color="#594E26"
      sx={{ borderRadius: 12, border: '1px solid #F0E5C2' }}
      py={3}
    >
      <Text as={FaVideo} size={32} />
      <Text>Record a {isFromRecordVideo ? 'Video Selfie' : 'Chat'}</Text>
      {!isFromRecordVideo && <Text fontSize={1}>(beta)</Text>}
    </Button>
  </>
  );
};

RecordChatButton.propTypes = {
  onRecordChat: PropTypes.func.isRequired,
};

RecordChatButton.defaultProps = {
  onRecordChat: () => { },
};

export default RecordChatButton;
