import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { display } from 'styled-system';
import { useIsNavigationVisible } from 'ui/hooks';

// import AddButton from '../add-button';
import * as Links from '../links';
import { TagContext } from '../../../collections/detail/tag-context';
import { useHasDrawer } from '../../mobile-drawer/hooks';
import { useLocation } from 'react-router-dom';

const NavFooterWrap = styled.div`
  background: ${props => props.theme.colours.white};
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 52px;
  z-index: 103;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid ${props => props.theme.colours.border};
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
  ${display};
`;

const NavFooter = () => {
  const { pathname } = useLocation();
  const isPublicRoute = pathname.includes('/tell_a_story')

  const { showMilestones } = useContext(TagContext);

  const isVisible = useIsNavigationVisible();
  const hasDrawer = useHasDrawer();
  if (!hasDrawer || !isVisible || isPublicRoute) return false;

  return (
    <NavFooterWrap>
      {/* <Links.Chronicles /> */}
      <Links.Notifications />
      <Links.Me />
      {/* {!showMilestones && <AddButton />} */}
    </NavFooterWrap>
  );
};

export default NavFooter;
