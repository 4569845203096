import React from 'react';
import { Link, Button, Flex, Card, Image } from 'rebass';
import authURL from '../utils/auth-url';
import wordmark from '../app/logo/wordmark.svg';

const Login = () => {
  return (
    <Flex css="height: 100vh; width: 100vw;" alignItems="center" justifyContent="center">
      <Card py={4} px={3} bg="white" boxShadow="container" borderRadius={3}>
        <Flex flexDirection="column">
          <Image src={wordmark} width={300} alt="lifetales" m={3} />
          <Button variant="primary" fontSize={3} py={3} px={4} m={2} as={Link} href={authURL}>
            Sign In To LifeTales
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
};

export default Login;

// /token/7b9d6675-9a1a-4083-b992-a2e24bd43d41/secret/961255920bda80cd20692bed6d72e036
