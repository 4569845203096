import React, { useState } from 'react';
import { Text, Box, Flex } from 'rebass';
import { useDispatch } from 'react-redux';
import RecordVideo from './RecordVideo';
import { createMediaForFiles, createTextMedium } from 'story-media/actions';
import RecordAudio from './RecordAudio';
import AddText from './AddText';
import Footer from './Footer';
import UploadMedia from './UploadMedia';
import QuestionBar from './QuestionBar';

const Step3 = ({ attributes, storyId, onStepUp, percentComplete, onStepDown }) => {
    const [selectActionItem, setSelectedActionItem] = useState(null);

    const dispatch = useDispatch();

    const submitText = (values) => dispatch(createTextMedium(storyId, values), []);

    const submitMedia = (files, position) => {
        dispatch(createMediaForFiles(storyId, files, position));
        onStepUp()
    }

    const onSubmitMedia = files => {
        return submitMedia(files, 1);
    }

    return (
        <Flex flexDirection="column" maxWidth={500} m="auto" mt={['10px', '100px']} justifyContent="center" alignItems="left">
            <QuestionBar question={attributes?.question} />
            <Text
                px={2}
                textAlign="left"
                fontFamily='Zilla Slab'
                fontSize={30}
                lineHeight="120%"
                letterSpacing='-0.66px'
                my={3}
            >
                Would you like to add anything else?
            </Text>
            <Box p={2}>
                <UploadMedia question={attributes?.question} isSelected={selectActionItem === 'media'} onClick={() => setSelectedActionItem('media')} onSubmitMedia={onSubmitMedia} />
                <RecordVideo question={attributes?.question} isSelected={selectActionItem === 'record'} onClick={() => setSelectedActionItem('record')} onSubmitMedia={onSubmitMedia} />
                <AddText
                    question={attributes?.question}
                    isSelected={selectActionItem === 'text'}
                    onClick={() => setSelectedActionItem('text')}
                    onSubmitText={({ text }) => submitText({ text, 'position': 0 }).then(() => setSelectedActionItem(null))}
                />

                <RecordAudio
                    question={attributes?.question}
                    isSelected={selectActionItem === 'audio'}
                    onClick={() => setSelectedActionItem('audio')}
                    onSubmitMedia={onSubmitMedia}
                />
            </Box>

            <Footer borderRadius="200px" onNextPress={onStepUp} percentComplete={percentComplete} nextButtonText="Nope, that’s all!" />
        </Flex>
    );
};

export default Step3;
