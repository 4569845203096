import { Box, Button, Heading } from 'rebass';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import { Form, Submit, SkipLink } from '../form-styled';
import SmallText from '../small-text';
import DateField from '../../../app/form/formik/date';

const StyledInput = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
`;

const NewCollectionDate = ({ isValid, isSubmitting, label, skipPath }) => (
  <Form height={360} autoComplete="off">
    <Box width="100%" my="auto" pb={40}>
      <Heading textAlign="center" fontSize={24} fontWeight="normal" py={14}>
        {label}
      </Heading>
      <StyledInput>
        <Field component={DateField} name="date-of-birth" width="100%" />
      </StyledInput>
      <SmallText textAlign="center" color="mutedText" fontSize={16} px={20} lineHeight="23px">
        (We do not share this information with anyone. It is only used to make your memoir
        experience better.)
      </SmallText>
    </Box>
    <Submit as={Button} variant="primary" disabled={!isValid && !isSubmitting} fontSize={20}>
      Next
    </Submit>
    <SkipLink to={skipPath}>Skip</SkipLink>
  </Form>
);

NewCollectionDate.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  skipPath: PropTypes.string.isRequired,
};

export default NewCollectionDate;
