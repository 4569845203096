import { Formik } from 'formik';
import { object, date } from 'yup';
import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { trigger } from 'swr';

import { patchMemoir } from '../api';
import { prepareAttrs } from '../../utils/api';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import { useHistory, useJsDate, useMatch } from '../../app/hooks';
import FullPageForm from '../../app/full-page-form';
import NewCollectionDate from '../../collections/new/date';
import { requestEntities } from '../../entities/actions';
import { postMilestone } from '../milestones/api';

const MemoirDate = () => {
  const { push } = useHistory();
  const { params } = useMatch();
  const { entity } = useEntity(params);
  const { receive } = useEntityActionCreators();
  const initialDate = useJsDate(entity['date-of-birth']);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => trigger('/v3/my/chronicle_collection');
  }, []);

  const initialValues = {
    'date-of-birth': initialDate || null,
  };
  const validationSchema = object().shape({
    'date-of-birth': date(),
  });
  const isInitialValid = validationSchema.isValidSync(initialValues);

  const label = useMemo(
    () =>
      entity['subject-relation'] === 'me'
        ? 'When were you born?'
        : `When was ${entity['first-name']} born?`,
    [entity]
  );
  const backPath = useMemo(
    () => (entity['subject-relation'] === 'me' ? 'memoir-name' : 'subject-name'),
    [entity]
  );
  return (
    <FullPageForm
      onBackClick={() => push(`/memoirs/new/${entity.id}/${backPath}`)}
      title="Create a Memoir"
    >
      <Formik
        {...{ initialValues, validationSchema, isInitialValid }}
        onSubmit={async values => {
          const body = prepareAttrs({
            ...values,
            'lock-version': entity['lock-version'],
          });

          // PATCH child journal
          const cjRes = await patchMemoir({ id: entity.id, ...body });
          const [id] = cjRes.data.result.memoirs;
          receive({ payload: cjRes });
          if (!(entity['milestone-shares'] || []).length) {
            dispatch(
              requestEntities(
                postMilestone({
                  memoirId: entity.id,
                  attributes: {
                    'start-date': values['date-of-birth'],
                    name: `${entity['subject-name']} was born`,
                    'milestone-kind': 'birth',
                  },
                })
              )
            );
          }
          push(`/memoirs/new/${id}/is-with-us`);
        }}
        render={formikProps => (
          <NewCollectionDate
            label={label}
            skipPath={`/memoirs/new/${entity.id}/is-with-us`}
            {...formikProps}
          />
        )}
      />
    </FullPageForm>
  );
};

export default MemoirDate;
