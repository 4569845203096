import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { trigger } from 'swr';

import { ReactComponent as AlertIcon } from '../../collections/detail/delete/alert.svg';
import { CurrentCollectionContext } from '../../collections/current-collection-context';
import { destroyStory } from '../actions';
import { setCheckmark } from '../../ui/actions';
import { useEntity, useEntityActionCreators } from '../../entities/hooks';
import { useHistory } from 'react-router-dom';
import { useRouteFromEntityParams } from '../../collections/hooks';
import DeleteCollectionModal from '../../collections/detail/delete/modal';
import { StoryOptionsContext } from '../options';

const DeleteModal = props => {
  const { storyId: id, onClose } = useContext(StoryOptionsContext);
  const { currentCollection } = useContext(CurrentCollectionContext);
  const { replace } = useHistory();
  const { receiveDeletion } = useEntityActionCreators();
  const { entity } = useEntity({ id, type: 'stories' });
  const route = useRouteFromEntityParams({
    type: currentCollection.type,
    id: currentCollection.id,
  });
  const [loading, setLoading] = useState(false);

  const onDestroyClick = () => {
    setLoading(true);

    props.destroyStory(id).then(() => {
      receiveDeletion({ entityType: 'stories', id });
      trigger('/v3/my/chronicle_collection');
      if (route) {
        replace(route);
      } else {
        replace('/my-stories');
      }

      props.setCheckmark({ message: 'Deleted' });
    });
  };

  return (
    <DeleteCollectionModal
      onClose={onClose}
      onCancelClick={onClose}
      cancelText="Cancel"
      destroyText="Delete Now"
      onDestroyClick={onDestroyClick}
      headerIcon={<AlertIcon />}
      bodyTitle={`Delete "${entity.title}" from all Memoirs?`}
      bodyText={`Deleting "${entity.title}" will delete it from all Memoirs.`}
      disabled={loading}
    />
  );
};

DeleteModal.propTypes = {
  destroyStory: PropTypes.func.isRequired,
  setCheckmark: PropTypes.func.isRequired,
};

export default connect(null, { destroyStory, setCheckmark })(DeleteModal);
