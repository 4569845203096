
import { Button, Text, Flex, Heading, Image } from 'rebass';
import React, { useState, useCallback } from 'react';
import FontAwesome from 'react-fontawesome';
import Tooltip from '@tippy.js/react';
// import PropTypes from 'prop-types';
import { useMatch } from '../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { useEntity } from '../../../entities/hooks';
import Modal from '../../../app/modal/v2';
import MilestoneDate from '../date';
import MilestoneLocation from '../location';
import EllipsisIcon from '../../../assets/svg/ellipsis-icon';
import { TooltipWrap, TooltipButton } from '../../../stories/story-detail-header/styled';
import DeleteMilestone from './delete';
import MilestoneEmpty from './empty';

const MilestoneDetail = () => {
  const { replace } = useHistory();
  const {
    params: { id, milestoneId },
  } = useMatch();
  const {
    milestone: { title, location, note, 'start-date': date, 'created-at': createdAt, ...milestone },
  } = useEntity({ id: milestoneId, type: 'milestones' });
  const { entity: image, isPresent: imagePresent } = useEntity(milestone['cover-image']);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [confirming, setConfirming] = useState(false);

  const onClose = useCallback(() => { replace(`/memoirs/${id}/milestones`) }, [id, replace]);

  const handleEditClick = () => {
    setDropdownOpen(false);
    replace(`/memoirs/${id}/life-events/${milestoneId}/edit`);
  };
  console.log(confirming, " confirming")
  return (
    <Modal closeButtonLeft closeOnOutsideClick={false} onClose={onClose} aboveTooltip>
      <DeleteMilestone
        milestoneId={milestone.id}
        memoirId={id}
        isVisible={confirming}
        onCancel={() => setConfirming(false)}
        onSuccess={onClose}
      />
      <Tooltip
        theme="light"
        trigger="manual"
        placement="bottom left"
        arrow="small"
        interactive
        isVisible={dropdownOpen}
        onHidden={() => dropdownOpen && setDropdownOpen(false)}
        zIndex={10002}
        content={
          <TooltipWrap>
            <TooltipButton onClick={handleEditClick}>
              <FontAwesome name="pencil" /> Edit
            </TooltipButton>
            <TooltipButton
              onClick={(e) => {
                console.log(e, "CLICKED")
                e.preventDefault();
                e.stopPropagation();
                setDropdownOpen(false);
                setConfirming(true);
              }}
              destructive
            >
              <FontAwesome name="trash" /> Delete
            </TooltipButton>
          </TooltipWrap>
        }
      >
        <Button
          onClick={() => (dropdownOpen ? setDropdownOpen(false) : setDropdownOpen(true))}
          variant="transparent"
          ml="auto"
          css="display: flex; padding: 16px;"
        >
          <EllipsisIcon dark />
        </Button>
      </Tooltip>
      <Flex p={3} flexDirection="column">
        <Heading fontSize={36} textAlign="center" pb={3} fontWeight="normal" color="defaultText">
          {title}
        </Heading>
        <Flex justifyContent="center" alignItems="center" pb={4}>
          <MilestoneDate date={date} />
          <MilestoneLocation date={location} />
        </Flex>
        {note && (
          <Text color="mutedText" mt={2}>
            {note}
          </Text>
        )}
        {imagePresent && (
          <Image
            mt={2}
            src={image.tmpUrl || (image['image-versions'][3] && image['image-versions'][3].url)}
          />
        )}
        {!note && !imagePresent && <MilestoneEmpty onClick={handleEditClick} />}
      </Flex>
    </Modal>
  );
};

MilestoneDetail.propTypes = {};

export default MilestoneDetail;
