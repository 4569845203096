import React from 'react';
import { layout } from 'styled-system';
import styled from '@emotion/styled';
import { Text, Box, Button } from 'rebass';
import { FaArrowRight } from 'react-icons/fa';

const DisplayBox = styled.div`
${layout};
`;

const ActionItems = React.forwardRef(
    ({ icon, iconSize, isSelected, label, children, size, labelProps, selected, nextIcon = false, ...props }, ref) => {

        const iconColour = isSelected ? '#fff' : "#766AB9";
        const labelColor = isSelected ? '#fff' : "#766AB9";
        return (
            <Button
                variant="transparent"
                type="button"
                color="white"
                // style={{ }}
                css="display: flex; justify-content: center; align-items: center;"
                sx={{
                    minHeight: size, minWidth: size,
                    width: '100%',
                    borderRadius: 200,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    border: '1px solid',
                    borderColor: labelColor,
                    fontWeight: 'normal',
                }}
                p={'16px'}
                px={'20px'}
                ref={ref}
                bg={isSelected ? "#53F" : "#fff"}
                {...props}
            >
                <Box mr={1} as={icon} color={iconColour} size={iconSize} />
                {label && (
                    <DisplayBox mr={1} display={['inline-block']}>
                        <Text color={labelColor} px={1} fontSize={1}>
                            {label}
                        </Text>
                    </DisplayBox>
                )}
                {nextIcon && <Box as={FaArrowRight} color={iconColour} size={iconSize} />}
            </Button>
        );
    }
);

export default ActionItems