

import { FaImage } from 'react-icons/fa';
import FontAwesome from 'react-fontawesome';
import { Image, Text, Box, Flex, Heading } from 'rebass';
import PropTypes from 'prop-types';
import React, { useMemo, useContext, useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';
import DrawerIconLink from './sidebar-icon-link';
import { useEntity } from '../../../entities/hooks';
import { useRouteFromEntityParams } from '../../../collections/hooks';
import CircleIcon from '../../icons/circle-icon';
import { CurrentCollectionContext } from '../../../collections/current-collection-context';
import {
  useUnreadCollectionIds,
  useCollectionUnreadStoryCount,
} from '../../../notifications/notification/helper';
import { useCollectionCoverImage } from '../../../collections/hooks/cover';
import { useCollectionTitle } from '../../../collections/shared/title';
import { usePaymentBanner, usePaymentUrl } from 'memoirs/new/hooks';
import useCurrentMemberId, { useMemberById } from 'members/hooks/index';
import Modal from 'app/modal/v2';
import { TooltipButton } from 'stories/story-detail-header/styled';

const SidebarCollectionLink = ({ id, storyCount, membersCount, type, onClick }) => {
  const { push, replace } = useHistory();
  const { currentCollection, setCurrentCollection } = useContext(CurrentCollectionContext);
  const { entity: share } = useEntity({ id, type });
  const parentType = useMemo(() => type.replace('-shares', ''), [type]);
  const memoirParams = share.journal || share[parentType];
  const { entity: memoir, isPresent } = useEntity(memoirParams);
  const imgSrc = useCollectionCoverImage({ size: 7, ...memoirParams });
  const memoirRoute = useRouteFromEntityParams(memoirParams);
  const unreadCollectionIds = useUnreadCollectionIds();
  const hasBadge = unreadCollectionIds.includes(memoir.id);
  const unreadStoryCount = useCollectionUnreadStoryCount(memoir.id);
  const title = useCollectionTitle(memoirParams);
  const memoirCreator = useMemberById(memoir.member && memoir?.member?.id);

  const [stripeUrl, fetchPaymentUrl] = usePaymentUrl(memoir?.id, memoir)
  const isUnderTrial = usePaymentBanner(memoir);
  const route = useRouteFromEntityParams({ type: 'memoirs', id: memoir?.id });
  const [showWarning, setShowWarning] = useState(false)
  const memoirType = type.replace('-shares', 's');
  const isSelected = memoir.id === ((currentCollection && currentCollection.id) || false);

  const currentMemberId = useCurrentMemberId();
  const isCurrentMemberCreator = currentMemberId === memoir?.['member']?.id

  useEffect(() => {
    if (memoir?.paid === false && !stripeUrl && !memoir?.['stripe-hosted-payment-url'] && isCurrentMemberCreator && isUnderTrial === false) {
      fetchPaymentUrl()
    }

  }, [memoir?.paid, stripeUrl, isCurrentMemberCreator, isUnderTrial]);

  useEffect(() => {
    if (isSelected && !isCurrentMemberCreator && !memoir?.paid && !isUnderTrial) {
      push(`/`);
      setCurrentCollection(null)
    }
  }, [isSelected, isCurrentMemberCreator, setCurrentCollection, memoir?.paid, isUnderTrial])

  const sublabel = useMemo(
    () => `${pluralize('stories', storyCount, true)} - ${pluralize('members', membersCount, true)}`,
    [membersCount, storyCount]
  );

  if (!isPresent) return false;

  return (
    <Box sx={{ position: 'relative' }} my={1}>
      {showWarning && <Modal
        onClose={() => setShowWarning(false)}
        maxWidth={400}
        title={""}
      >
        <Box p={4}>
          <Heading fontSize={2} pb={1} fontFamily="sans" textAlign="center">
            This Memoir is currently unpaid and not viewable. Please contact {memoirCreator?.['full-name']} for details
          </Heading>
        </Box>
      </Modal>}
      <DrawerIconLink
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick();
          if (memoir?.paid || isUnderTrial || isCurrentMemberCreator) {
            setCurrentCollection({ type: memoirType, id: memoir.id });
            push(`${memoirRoute}`);
          } else {
            setShowWarning(true);
          }
        }}
        label={title}
        sublabel={sublabel}
        isSelected={isSelected}
        hasBadge={hasBadge}
        unreadStoryCount={unreadStoryCount}
        isSample={memoir['is-a-sample']}
      >
        <CircleIcon
          size={40}
          border="2px solid"
          bg="drawerSubtle"
          borderColor="drawerBasicallyWhite"
          borderRadius="8px"
          minWidth="40px"
        >
          {imgSrc && <Image src={imgSrc} height={40} width={40} css="object-fit: cover;" />}
          {!imgSrc && <Text as={FaImage} size={26} color="drawerBasicallyWhite" />}
        </CircleIcon>
      </DrawerIconLink>
      {memoir?.paid === false && isCurrentMemberCreator && !isUnderTrial && (
        <Flex
          flexDirection="column"
          sx={{
            width: '90px',
            height: '100%',
            right: 0,
            top: '50%',
            bottom: '50%',
            transform: 'translateY(-50%)',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '7px 0px 0px 7px',
            bottom: 0,
            color: '#FFF',
            bg: 'rgba(0, 0, 0, 0.65)',
          }}>
          <Flex
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(stripeUrl, '_blank');
            }}
            p="10px"
            sx={{
              height: '50%',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '120%',
              letterSpacing: '-0.352px',
              textAlign: 'left',
              cursor: 'pointer',
              textDecorationLine: 'underline',
            }}
          >
            Pay Now
          </Flex>
          <TooltipButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              replace(`${route}/delete`);
            }}
            destructive
          >
            <FontAwesome name="trash" />
          </TooltipButton>
        </Flex>
      )}
    </Box>
  );
};

SidebarCollectionLink.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  storyCount: PropTypes.number.isRequired,
};

export default SidebarCollectionLink;
