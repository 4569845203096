import React, { useState, useEffect } from 'react';
import { Box, Flex } from 'rebass';
import { v4 } from 'uuid';
// import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
// import { VoiceRecorder } from "react-voice-recorder-player";
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import Modal from 'app/modal/v3';
import ActionItems from './ActionItems';
import { FaMicrophone } from 'react-icons/fa';
import AudioPlayer from './AudioPlayer';
import RecordButton from 'chats/RecordButton';
import { ControlButtons } from 'chats/VideoPreview';

function RecordAudio({ onSubmitMedia, question, isSelected, onClick }) {
    const [startRecording, setStartRecording] = useState(false);
    const [recordState, setRecordState] = useState(RecordState.NONE);
    const [file, setFile] = useState(null);
    const [url, setUrl] = useState(null);

    const [isPlaying, setIsPlaying] = useState(false)

    // const recorderControls = useAudioRecorder()

    const handleClick = async () => {
        setStartRecording(true)
        onClick()
        return
    };

    useEffect(() => {
        if (!startRecording)
            setFile(null)
    }, [startRecording])

    const handleChange = (e) => {
        return onSubmitMedia(e.target.files);
    }


    const onStop = ({ blob, url, type }) => {
        blob.name = `${v4()}.${type.split('/')[1]}`;
        setFile(blob)
        setUrl(url)
    };

    return <>
        {startRecording && <Modal
            close={{
                onClose: () => {
                    setFile(null)
                    setUrl(null)
                    setStartRecording(false)
                }
            }}
            maxWidth={800}
            title={question}
            closeOnOutsideClick={false}
            aboveTooltip
        >
            <Flex flexDirection="column" alignItems="center">
                {url ?
                    <AudioPlayer
                        src={url}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                    />
                    : <Box bg="#000" sx={{ borderRadius: '4px' }}>
                        <AudioReactRecorder
                            canvasWidth={300}
                            canvasHeight={180}
                            foregroundColor="#fff"
                            backgroundColor="#000"
                            state={recordState}
                            onStop={onStop}
                        />
                    </Box>
                }
                {url ?
                    <ControlButtons
                        isPlaying={isPlaying}
                        handlePlayPause={() => setIsPlaying(!isPlaying)}
                        onReject={() => {
                            setFile(null)
                            setUrl(null)
                        }}
                        rejectLabel="Re-record"
                        onAccept={() => {
                            const newFile = new File([file], file.name, { type: file.type, lastModified: Date.now() });
                            handleChange({ target: { files: [newFile] } })
                            setStartRecording(false)
                            setFile(null)
                            setUrl(null)
                        }}
                    />
                    : <RecordButton
                        isRecording={recordState === RecordState.START}
                        onClick={() => {
                            if (recordState === RecordState.START) {
                                setRecordState(RecordState.STOP)
                            }
                            else {
                                setRecordState(RecordState.START)
                            }
                        }}
                    />}
            </Flex>
        </Modal>
        }


        <ActionItems
            onClick={handleClick}
            isSelected={isSelected}
            nextIcon={isSelected}
            icon={FaMicrophone}
            mt={2}
            label="Respond with audio"
        />
    </>
}

export default RecordAudio;