export const relationshipOptions = [
  { label: 'Me', value: 'me' },
  { label: 'My spouse', value: 'spouse' },
  { label: 'My child', value: 'child' },
  { label: 'My mom', value: 'mom' },
  { label: 'My dad', value: 'dad' },
  { label: 'My grandma', value: 'grandma' },
  { label: 'My grandpa', value: 'grandpa' },
  { label: 'My great aunt', value: 'great_aunt' },
  { label: 'My great uncle', value: 'great_uncle' },
  { label: 'Other', value: 'other' },
];

export default relationshipOptions;
