import { FaCheck, FaTrash, FaTimes } from 'react-icons/fa';
import { Flex, Text, Button, Box } from 'rebass';
import { UserAgent } from '@quentin-sommer/react-useragent';
import AvatarEditor from 'react-avatar-editor';
import DropZone from 'react-dropzone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ScrollLock from 'react-scrolllock';
import * as loadImage from 'blueimp-load-image';

import { Entity } from '../../../../entities';
import { PillButton } from '../../../buttons/styled';
import { acceptedFor } from '../../../../story-media/helper';
import AvatarPreview from './image';

const CircleButton = ({ children, size, ...props }) => (
  <Button
    type="button"
    borderRadius="50%"
    style={{ height: size, width: size, position: 'relative' }}
    px={0}
    py={0}
    {...props}
  >
    <Box
      css="position: absolute; top: 50%; left: 50%; transform: translateY(-50%) translateX(-50%); line-height: 1; display: inline-flex;"
      as="span"
    >
      {children}
    </Box>
  </Button>
);
CircleButton.propTypes = { children: PropTypes.node.isRequired, size: PropTypes.number };
CircleButton.defaultProps = { size: 24 };

const initialState = {
  mounting: false,
  zoom: 1,
  image: null,
  staged: false,
  preview: null,
  initialFile: null,
};

class CoverField extends Component {
  state = {
    ...initialState,
  };

  componentDidMount() {
    this.getInitialPreview();
  }

  componentWillUnmount() {
    const { image } = this.state;
    URL.revokeObjectURL(image);
  }

  getInitialPreview() {
    const {
      field: { value },
    } = this.props;
    if (value) {
      this.setState({ preview: value });
    }
  }

  handleChange = async () => {
    const { initialFile } = this.state;
    const canvas = this.editor.getImage();
    const { field, form } = this.props;
    const file = await new Promise(resolve => {
      canvas.toBlob(
        blob => {
          const newImage = blob;
          newImage.name = initialFile.name;
          newImage.lastModifiedAt = new Date();
          resolve(newImage);
        },
        initialFile.type,
        100
      );
    });
    form.setFieldValue(field.name, file);
  };

  handleConfirm = () => {
    const {
      field: { value },
    } = this.props;
    this.setState({
      ...initialState,
      preview: value,
    });
  };

  handleCancel = () => {
    const { field, form } = this.props;
    form.setFieldValue(field.name, null);
    this.setState({
      zoom: 1,
      image: null,
      staged: false,
      preview: null,
    });
  };

  handleMount = async file => {
    this.setState(prev => ({ ...prev, initialFile: file, mounting: true }));
    const { field, form } = this.props;
    const processedFile = await new Promise(resolve => {
      loadImage(
        file,
        canvas => {
          canvas.toBlob(
            blob => {
              const nextFile = blob;
              nextFile.lastModifiedAt = new Date();
              nextFile.name = file.name;
              resolve(blob);
            },
            file.type,
            100
          );
        },
        {
          orientation: true,
          canvas: true
        }
      );
    });
    form.setFieldValue(field.name, processedFile);
    this.setState({
      image: URL.createObjectURL(processedFile),
      mounting: false,
      preview: null,
      staged: true,
    });
  };

  render() {
    const { width, currentCover, circular, hasConfirm, hasEditButton, destroyCover } = this.props;
    const { zoom, mounting, image, staged, preview } = this.state;

    const height = circular ? width : width / 2;

    return (
      <>
        {!staged && destroyCover && (
          <CircleButton
            ml={width - 12}
            mb={-12}
            bg="red"
            type="button"
            onClick={destroyCover}
            css="display: block; z-index: 1;"
          >
            <FaTimes color="white" />
          </CircleButton>
        )}
        <UserAgent android>
          {isAndroid => (
            <DropZone
              onDrop={dropped => this.handleMount(dropped[0])}
              disableClick={staged}
              accept={isAndroid ? 'image/*' : acceptedFor.images}
              style={{ margin: '0 auto', cursor: 'pointer' }}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <ScrollLock isActive={staged}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {staged && (
                      <AvatarEditor
                        ref={node => {
                          this.editor = node;
                        }}
                        image={image}
                        width={circular ? width : width - 40}
                        height={circular ? width : height - 20}
                        border={20}
                        borderRadius={circular ? width / 2 : 0}
                        scale={zoom}
                        onMouseUp={() => this.handleChange()}
                        onImageReady={() => this.handleChange()}
                      />
                    )}
                    {!staged && !preview && currentCover && (
                      <Entity type="images" id={currentCover}>
                        {({ entity }) => (
                          // isPresent && (
                          <div css="position: relative">
                            <AvatarPreview
                              height={height}
                              width={width}
                              src={
                                entity.tmpUrl ||
                                (entity['image-versions'] && entity['image-versions'][3].url) ||
                                entity['asset-url']
                              }
                              alt="Preview"
                              borderRadius={circular ? '50%' : '0px'}
                              circular={circular}
                              pose={mounting ? 'loading' : null}
                              hasEditButton={hasEditButton}
                            />
                          </div>
                        )
                          // )
                        }
                      </Entity>
                    )}
                    {(preview || (!staged && !currentCover)) && (
                      <AvatarPreview
                        height={height}
                        width={width}
                        src={preview}
                        alt="Preview"
                        borderRadius={circular ? '50%' : '0px'}
                        circular={circular}
                        pose={mounting ? 'loading' : null}
                        hasEditButton={hasEditButton}
                      />
                    )}
                  </div>
                </ScrollLock>
              )}
            </DropZone>
          )}
        </UserAgent>
        {staged ? (
          <Flex mt={2}>
            <label
              htmlFor="zoom-input"
              style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}
            >
              <Text as="span" pr={1}>
                Zoom
              </Text>
              <input
                type="range"
                id="zoom-input"
                onChange={e => {
                  this.setState({ zoom: Number(e.target.value) });
                }}
                onMouseUp={() => {
                  this.handleChange();
                }}
                min="1"
                max="2"
                step="0.01"
                value={zoom}
              />
            </label>
            <PillButton
              onClick={this.handleCancel}
              type="button"
              destructive
              style={{ margin: hasConfirm ? '0 1em' : '0 0 0 1em' }}
            >
              <FaTrash style={{ marginRight: '4px' }} />
              Remove
            </PillButton>
            {hasConfirm && (
              <PillButton onClick={this.handleConfirm} type="button" primary>
                <FaCheck style={{ marginRight: '4px' }} />
                Confirm
              </PillButton>
            )}
          </Flex>
        ) : (
          false
        )}
      </>
    );
  }
}

CoverField.propTypes = {
  width: PropTypes.number,
  field: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(File),
      PropTypes.instanceOf(Blob),
    ]),
  }).isRequired,
  form: PropTypes.shape({}).isRequired,
  currentCover: PropTypes.string,
  circular: PropTypes.bool,
  hasConfirm: PropTypes.bool,
  hasEditButton: PropTypes.bool,
  destroyCover: PropTypes.func,
};

CoverField.defaultProps = {
  width: 150,
  currentCover: '',
  circular: false,
  hasConfirm: true,
  hasEditButton: true,
  destroyCover: null,
};

export default CoverField;
