import { Box, Flex, Button, Text } from 'rebass';
import { space } from 'styled-system';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import normalize from 'jsonapi-normalizer';

import { useResetCollectionTag, useHowDoICollection } from 'collections/hooks';
import { CurrentCollectionContext } from '../../../collections/current-collection-context';
import { CurrentModalContext } from '../../modal/context';
import { colours } from '../../styles/theme';
import { useChronicleCollectionSWR } from '../../../collections/api';
import Loading from '../../loading/index';
import PlusIcon from '../../../assets/svg/plus-icon';
import SidebarCollectionLink from './collection-link';
import HowDoILink from './how-do-i-link';
import { useHowDoICollectionPath } from './hooks';

const NavigationSidebar = ({ onCollectionClick }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { setCurrentCollection } = useContext(CurrentCollectionContext);
  const { setCurrentModal, revalidateSidebar } = useContext(CurrentModalContext);
  const resetCollectionTag = useResetCollectionTag();

  const { data, error, revalidate } = useChronicleCollectionSWR();
  const loading = !data && !error;
  const shares = useMemo(() => {
    return (data?.data?.data || []).map(d => d.relationships["memoir-shares"].data[0]);
  }, [data]);

  const normalizedPayload = useMemo(() => (data ? normalize(data.data) : {}), [data]);
  const isPublicRoute = pathname.includes('/tell_a_story')

  useEffect(() => {
    if (revalidateSidebar)
      revalidate()
  }, [revalidateSidebar]);

  useEffect(() => {
    if (pathname === '/my-stories' || pathname === '/shared-with-me' || pathname === '/') {
      setCurrentCollection(null);
    }
  }, [pathname, setCurrentCollection]);

  const { entity, isPresent } = useHowDoICollection();
  const howDoIPath = useHowDoICollectionPath();

  const goTo = (path = '') => {
    onCollectionClick();
    resetCollectionTag();
    setCurrentCollection(null);
    push(path);
  };

  if (isPublicRoute)
    return null

  return (
    <Wrap py={3}>
      {/* <SharedWithMeLink onClick={() => goTo('/shared-with-me')} /> */}
      {/* <MyStoriesLink onClick={() => goTo('/my-stories')} /> */}
      {isPresent && (
        <HowDoILink
          onClick={() => {
            goTo(howDoIPath);
          }}
          label={entity.name}
        />
      )}
      <Flex alignItems="center" px={3} css="min-height: 40px;">
        <Text color="drawerBasicallyWhite" fontWeight="bold" fontFamily="sans" fontSize={2}>
          Memoirs
        </Text>
        <Button
          variant="transparent"
          onClick={() => {
            onCollectionClick();
            setCurrentModal('new-collection');
            resetCollectionTag();
          }}
          ml="auto"
          css="padding-right: 0;"
        >
          <PlusIcon size="22px" colour={colours.drawerSubtle} />
        </Button>
      </Flex>
      <Box css="overflow-y: scroll;">
        {loading && <Loading local />}
        {shares.map(share => {
          // we show how do i collection link elsewhere
          if (share.type.includes('how-do-i')) return false;

          const shareEntity = normalizedPayload.entities[share.type][share.id];
          const journalEntity =
            normalizedPayload.entities[shareEntity.journal.type][shareEntity.journal.id];

          return (
            <SidebarCollectionLink
              onClick={() => {
                onCollectionClick();
                resetCollectionTag();
              }}
              id={share.id}
              type={share.type}
              key={share.id}
              storyCount={journalEntity['story-count'] || 0}
              membersCount={journalEntity['member-count'] || 0}
            />
          );
        })}
      </Box>
    </Wrap>
  );
};
NavigationSidebar.propTypes = {
  onCollectionClick: PropTypes.func,
};
NavigationSidebar.defaultProps = {
  onCollectionClick: () => { },
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
  max-width: 270px;
  min-width: 270px;
  background-color: ${props => props.theme.colours.drawerBg};
  ${space};
`;

export default NavigationSidebar;
