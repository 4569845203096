import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { object, string } from 'yup';
import React from 'react';

import { postMemoir, destroyMemoir, patchMemoir } from '../../api';
import { prepareAttrs } from '../../../utils/api';
import { useHistory, useMatch } from '../../../app/hooks';
import { useCurrentMember } from '../../../members/hooks';
import { useEntity, useEntityActionCreators } from '../../../entities/hooks';
import FullPageForm from '../../../app/full-page-form';
import RelationshipForm from './form';

const MemoirRelationship = () => {
  const { params } = useMatch();
  const { push } = useHistory();
  const { entity: memoir } = useEntity({ ...params });
  const { receive, receiveDeletion } = useEntityActionCreators();
  const { 'first-name': firstName } = useCurrentMember();
  const initialValues = {
    'subject-relation-noac': (memoir && memoir['subject-relation']) || '',
  };
  const validationSchema = object().shape({
    'subject-relation-noac': string().required(),
  });
  const isInitialValid = validationSchema.isValidSync(initialValues);

  const backPath = '/';

  return (
    <FullPageForm
      onExitClick={() => {
        if (memoir && memoir.id) {
          destroyMemoir(memoir.id)
            .then(() => receiveDeletion({ entityType: 'memoirs', id: memoir.id }))
            .then(() => push(backPath));
        } else {
          push(backPath);
        }
      }}
      title="Create a Memoir"
    >
      <Formik
        {...{ initialValues, isInitialValid, validationSchema }}
        onSubmit={async values => {
          const currentUserIsSubject = values['subject-relation-noac'] === 'me';
          const getNextPath = id =>
            currentUserIsSubject
              ? `/memoirs/new/${id}/memoir-name`
              : `/memoirs/new/${id}/subject-name`;

          if (isEmpty(memoir)) {
            // POST memoir
            const attributes = prepareAttrs(values);
            if (currentUserIsSubject) {
              attributes['subject-name'] = firstName;
            }
            const body = {
              data: {
                attributes,
              },
            };
            const memoirRes = await postMemoir(body);
            const [id] = memoirRes.data.result.memoirs;
            receive({ payload: memoirRes });
            push(getNextPath(id));
          } else {
            // PATCH memoir
            const patchBody = prepareAttrs({ ...values, 'lock-version': memoir['lock-version'] });
            const memoirRes = await patchMemoir({ id: memoir.id, ...patchBody });
            const [id] = memoirRes.data.result.memoirs;
            receive({ payload: memoirRes });
            push(getNextPath(id));
          }
        }}
        render={props => <RelationshipForm {...props} />}
      />
    </FullPageForm>
  );
};

export default MemoirRelationship;
