import 'tippy.js/dist/themes/light.css';
import 'tippy.js/dist/tippy.css';

import './app.css';
import { Flex, Box } from 'rebass';
import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';

// import AndroidSaveBanner from 'app/banners/android-save-banner';
import Auth from '../auth';
import { AppContainer } from './styled';
import { QueryContextProvider } from '../contexts/query';
import { ServerErrorProvider, ServerErrorPopup } from './server-error-popup/index';
import AppCheckmark from '../ui/checkmark';
import AuthorizedContext from '../contexts/authorized';
import BrowserVersionsWrap from './browser-versions/wrap';
import Memoir from '../collections';
import CompleteOnboarding from '../members/complete-profile/complete-onboarding';
import CompleteProfile from '../members/complete-profile';
import ConnectionStatus from './connection-status';
import DesktopSidebar from './navigation/sidebar/desktop';
import Friends from '../friends';
import GlobalNewStory from '../stories/new/global';
import Header from './navigation/header';
import JSONAPIContext from './json-api-client/context';
import MobileDrawer from './mobile-drawer';
import MobileNavigation from './navigation/mobile';
import ModalRoutes from './modal-routes';
import MyStoriesPage from '../stories/pages/my-stories';
import Notifications from '../notifications';
import Profile from '../members/profile';
import PublicConnections from '../public-connections';
import SharedWithMePage from '../stories/pages/shared-with-me';
import Stories from '../stories/stories';
import StoryRequestDetail from '../story-requests/detail';
import SubscriptionCodeStorage from '../subscriptions/code-storage';
import UnloadWarning from './unload-warning';
import UploadManager from '../story-media/upload-manager';
import TellAStory from 'stories/tell-a-story/index';
import NewStoryRequest from 'collections/detail/story-requests/new/new-story-request';

const App = () => {
  return (
    <BrowserVersionsWrap>
      <AppContainer>
        <ServerErrorProvider>
          <JSONAPIContext>
            <QueryContextProvider>
              <Auth>
                <Route
                  path="/subscriptions/:subscriptionCode"
                  component={SubscriptionCodeStorage}
                />
                <ConnectionStatus />
                <UnloadWarning />
                <Switch>
                  <Route path="/member/:id" component={PublicConnections} />
                  <AuthorizedContext>
                    <ServerErrorPopup />
                    <MobileDrawer>
                      <Flex flexDirection="column">
                        <Header />
                        <Route path="/tell_a_story/:memoirId" component={TellAStory} />
                        {/* <AndroidSaveBanner /> */}
                        <Flex>
                          <DesktopSidebar />
                          <Box css="width: 100%;" mb={5}>
                            <Switch>
                              <Redirect
                                from="/memoir/:memoirId/story_request/:storyRequestId"
                                to="/memoirs/:memoirId/story-requests/:storyRequestId"
                              />
                              <Route path="/complete-onboarding" component={CompleteOnboarding} />
                              <Route path="/complete-profile" component={CompleteProfile} />
                              <Route path="/my-stories" component={MyStoriesPage} />
                              <Route path="/shared-with-me" component={SharedWithMePage} />
                              <Route path="/(stories|story)" component={Stories} />
                              <Route path="/profile" component={Profile} />
                              <Route path="/friends" component={Friends} />
                              <Route path="/notifications" component={Notifications} />
                              <Route path="/memoirs" component={Memoir} />
                              <Route path="/how-do-i" component={Memoir} />
                              <Route path="/custom" component={Memoir} />
                              <Route path="/child-journals" component={Memoir} />
                              <Route path="/family-journals" component={Memoir} />
                              <Route path="/personal-journals" component={Memoir} />
                              <Route
                                path="/story-requests/:storyRequestId"
                                component={StoryRequestDetail}
                              />
                              <Route path="/" component={SharedWithMePage} exact />
                              <Redirect from="/collections" to="/my-stories" exact />
                            </Switch>
                          </Box>
                        </Flex>
                        <MobileNavigation />
                        <GlobalNewStory />
                        <UploadManager />
                      </Flex>
                    </MobileDrawer>
                    <ModalRoutes />
                    <AppCheckmark />
                  </AuthorizedContext>
                </Switch>
              </Auth>
            </QueryContextProvider>
          </JSONAPIContext>
        </ServerErrorProvider>
      </AppContainer>
    </BrowserVersionsWrap>
  );
};

export default App;
