import { getAuthToken } from "./api";
import { useCallback } from 'react';
import store from 'store';
import { post } from "utils/api";

export const useCreateNewAccount = () => {
  return useCallback(
    async (attributes = undefined) => {
      const fetchToken = async ({ id, secret }) => {
        store.remove('auth_token');
        const newToken = await getAuthToken({ id, secret });
        store.set('auth_token', newToken);
      };
      let res = null;
      if (attributes) res = await post('/v3/memoir_members', { data: { attributes } });
      else res = await post('/v3/memoir_members');
      if (res.status === 200 || res.status === 201) {
        const { id, secret } = res.data.entities.restore_token[res.data.initial.data.id]

        const token = await fetchToken({ id, secret });
        return token;
      }
      return null;
    },
    []
  );
};