import React, { useState } from 'react';
import { Text, Box, Flex } from 'rebass';
import { useDispatch } from 'react-redux';
import RecordVideo from './RecordVideo';
import { createMediaForFiles, createTextMedium } from 'story-media/actions';
import RecordAudio from './RecordAudio';
import AddText from './AddText';
import VideoMedium from 'story-media/medium-types/video';
import Profile from './Profile';
import QuestionBar from './QuestionBar';
import UploadMedia from './UploadMedia';

function AddMediaSection({ attributes, onSubmitMedia, submitText }) {
    const [selectActionItem, setSelectedActionItem] = useState(null);

    return <Flex flexDirection="column">
        <Box>
            <UploadMedia question={attributes?.question} isSelected={selectActionItem === 'media'} onClick={() => setSelectedActionItem('media')} onSubmitMedia={onSubmitMedia} />
            <RecordVideo question={attributes?.question} isSelected={selectActionItem === 'record'} onClick={() => setSelectedActionItem('record')} onSubmitMedia={onSubmitMedia} />
            <AddText
                question={attributes?.question}
                isSelected={selectActionItem === 'text'}
                onClick={() => setSelectedActionItem('text')}
                onSubmitText={({ text }) => submitText({ text, 'position': 0 }).then(() => setSelectedActionItem(null))}
            />

            <RecordAudio
                question={attributes?.question}
                isSelected={selectActionItem === 'audio'}
                onClick={() => setSelectedActionItem('audio')}
                onSubmitMedia={onSubmitMedia}
            />
        </Box>
        <Text
            textAlign="center"
            fontFamily='Zilla Slab'
            fontSize={20}
            mt={3}
            lineHeight="120%"
            letterSpacing='-0.44px'
        >
            <b><i> You can add photos and videos</i></b> later.
        </Text>
    </Flex>
}

const Step1 = ({ subjectName, attributes, storyId, onStepUp }) => {
    const [uploadMediaSection, setUploadMediaSection] = useState(false)

    const medium = attributes?.['video-request']
    const contributorExperience = attributes?.['contributor-experience']

    const dispatch = useDispatch();

    const submitText = (values) => {
        dispatch(createTextMedium(storyId, values), [])
        onStepUp()
    };

    const submitMedia = (files, position) => {
        dispatch(createMediaForFiles(storyId, files, position));
        onStepUp()
    }

    const onSubmitMedia = files => {
        return submitMedia(files, 1);
    }

    return (
        <Flex flexDirection="column" maxWidth={500} m="auto" mt={['10px', '100px']} >
            {uploadMediaSection ? // for other_contributors
                <Flex flexDirection="column" justifyContent="center" alignItems="left">
                    <QuestionBar question={attributes?.question} />
                    <AddMediaSection attributes={attributes} onSubmitMedia={onSubmitMedia} submitText={submitText} />
                </Flex>
                : <Flex flexDirection="column" justifyContent="center" alignItems="center" p={2}>
                    <Text fontFamily='Zilla Slab' fontSize={20} lineHeight="120%" letterSpacing='-0.352px' >
                        {subjectName} wants to know...
                    </Text>
                    <Text
                        textAlign="center"
                        fontFamily='Zilla Slab'
                        fontSize={30}
                        mt={2}
                        lineHeight="120%"
                        letterSpacing='-0.66px'
                        fontStyle="italic"
                    >
                        {attributes?.question}
                    </Text>
                    <Box mt={20} >{medium?.type === 'videos' && <VideoMedium id={medium?.id} className="tellAStory" />}</Box>
                    {(contributorExperience === 'other_contributors') ?
                        <Profile label="What's your name?" onNextPress={() => setUploadMediaSection(true)} />
                        : <AddMediaSection attributes={attributes} onSubmitMedia={onSubmitMedia} submitText={submitText} />}
                </Flex>}
        </Flex>
    );
};

export default Step1;
