import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { left, color, display, boxShadow } from 'styled-system';
import { Box, Card, Button, Text, Image } from 'rebass';
import { GoThreeBars, GoX } from 'react-icons/go';
import store from 'store';
import NavigationSidebar from '../navigation/sidebar';
import { useHasDrawer } from './hooks';
import wordmark from '../logo/wordmark.svg';
import DrawerHeaderRightLinks from './right-links';
import { useLocation } from 'react-router-dom';

const Drawer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 270px;
  height: 100vh;
  transition: all 0.3s ease-in-out;
  z-index: 105;
  overflow-y: scroll;

  ${boxShadow};
  ${color};
  ${left};
`;

const DrawerShade = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 104;
  transition: background 0.2s ease-in-out;
  ${color};
  ${display};
`;

const MobileDrawer = ({ children }) => {
  const hasDrawer = useHasDrawer();
  const [on, setOn] = useState(false);
  const [shadeOn, setShadeOn] = useState(false);
  const { pathname } = useLocation();
  const isPublicRoute = pathname.includes('/tell_a_story')

  const toggleDrawer = useCallback(() => {
    if (on) {
      setOn(false);
      setTimeout(() => setShadeOn(false), 200);
    } else {
      setOn(true);
      setShadeOn(true);
    }
  }, [on]);

  useEffect(() => {
    if (!store.get('hasSeenDrawer') && !isPublicRoute) {
      toggleDrawer();
      store.set('hasSeenDrawer', true);
    }
  }, [toggleDrawer, isPublicRoute]);

  if (hasDrawer) {
    return (
      <Box>
        <Drawer
          bg="drawerBg"
          left={on ? 0 : -270}
          boxShadow={on ? '0 0 15px rgba(0, 0, 0, 0.3)' : 'none'}
        >
          <Box p={2}>
            <Button type="button" variant="transparent" onClick={toggleDrawer}>
              <Text color="white" as={GoX} />
            </Button>
          </Box>
          <NavigationSidebar onCollectionClick={toggleDrawer} />
        </Drawer>
        <DrawerShade
          bg={on ? 'rgba(0,0,0,.7)' : 'transparent'}
          display={shadeOn ? 'block' : 'none'}
          onClick={toggleDrawer}
        />
        <Card
          bg="white"
          p={2}
          css="display: grid; grid-template-columns: 25px 1fr 25px; position: sticky; top: 0; z-index: 2;"
        >
          {isPublicRoute ?
            <Image
              src={wordmark}
              alt="lifetales wordmark"
              height="30px"
              css="width: calc(100% - 21px);"
            />
            : <Button p={0} variant="transparent" type="button" onClick={toggleDrawer}>
              <GoThreeBars />
            </Button>}
          <Image
            src={wordmark}
            alt="lifetales wordmark"
            height="30px"
            css="width: calc(100% - 21px);"
          />
          <DrawerHeaderRightLinks />
        </Card>
        {children}
      </Box>
    );
  }
  return children;
};

MobileDrawer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MobileDrawer;
