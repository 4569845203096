import React from 'react';
import { Text, Box, Flex } from 'rebass';
import { Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import Footer from './Footer';
import useJsDate from 'app/hooks/js-date';
import { bodyWithLockVersion } from 'app/form/helper';
import { updateStory } from 'stories/actions';
import styled from '@emotion/styled';
import DateField from 'app/form/formik/date/index';
import QuestionBar from './QuestionBar';

const DatesWrap = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.gutter};
  grid-template-columns: 1fr;
  align-items: flex-end;
  ${props => props.theme.media.phone`
    grid-template-columns: repeat(2, 1fr);
  `};
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
`;

const Step5 = ({ attributes, storyId, onStepDown, onStepUp, story, percentComplete }) => {
    const dispatch = useDispatch();
    const initialBegan = useJsDate(story?.['began-on']);
    const initialEnded = useJsDate(story?.['ended-on']);
    const journalId = story?.['journal-ids'][0]

    return (
        <Flex flexDirection="column" maxWidth={500} m="auto" mt={['10px', '100px']} justifyContent="center" alignItems="left">
            <QuestionBar question={attributes?.question} />

            <Text
                px={3}
                textAlign="left"
                fontFamily='Zilla Slab'
                fontSize={30}
                lineHeight="120%"
                letterSpacing='-0.66px'
                my={3}
            >
                <i style={{ color: 'rgba(22, 22, 29, 0.70)' }}>(Optional)</i><br /> When did this story take place? (Roughly)
            </Text>

            <Formik
                initialValues={{
                    'began-on': initialBegan,
                    'ended-on': initialEnded,
                    id: storyId,
                    'journal-id': journalId,
                }}
                onSubmit={async values => dispatch(updateStory(storyId, bodyWithLockVersion(story, values))).then(() => {
                    onStepUp();
                })}
            >
                {({ values, handleSubmit }) => (
                    <Form style={{ padding: '16px' }}>
                        <DatesWrap>
                            <Field
                                name="began-on"
                                placeholder="Start Date"
                                maxDate={values['ended-on']}
                                component={DateField}
                                width="auto"
                            />
                            <Field
                                name="ended-on"
                                placeholder="End Date"
                                minDate={values['began-on']}
                                component={DateField}
                                width="auto"
                            />
                        </DatesWrap>
                        <Footer
                            onBack={onStepDown}
                            onNextPress={handleSubmit}
                            percentComplete={percentComplete}
                            nextButtonText="Finish"
                        />
                    </Form>
                )}
            </Formik>
        </Flex >
    );
};

export default Step5
