import React from 'react';
import { Flex, Button, Text, Box } from 'rebass';
import { IoIosArrowBack } from 'react-icons/io';
import Progress from './Progress';
// import { useFormikContext } from 'formik';

function Footer({
    onNextPress,
    onBack,
    type = '',
    nextButtonText,
    disabled = false,
    sx = {},
    middleButton = undefined,
    onSkipPress,
    onBackDisabled = false,
    percentComplete,
    borderRadius
}) {
    // const formik = useFormikContext();
    return (<Box
        maxWidth={500}
        sx={{
            position: 'fixed',
            bottom: 0,
            pb: 2,
            width: '100%',
            background: 'white',

            zIndex: 3,
        }}>
        <Progress percentComplete={percentComplete} />
        <Flex
            justifyContent="space-around"
            alignItems="flex-end"
            sx={{

                pt: [2, 2],
                ...sx,
            }}
        >
            {onBack && (
                <Flex
                    height={60}
                    justifyContent="space-around"
                    alignItems="center"
                    bg="#fff"
                    sx={{ borderRadius: 'medium', cursor: onBackDisabled ? 'not-allowed' : 'pointer' }}
                    p="0px 16px"
                    onClick={onBackDisabled ? undefined : onBack}
                    disabled={onBackDisabled}
                >
                    <Text as={IoIosArrowBack} color="black" fontSize={20} />
                    <Text color="black" fontWeight={500} fontSize="20px">
                        {' '}
                        Back
                    </Text>
                </Flex>
            )}
            {middleButton}
            <Flex>
                {onSkipPress && (
                    <Text
                        mr={3}
                        mt={2}
                        onClick={onSkipPress}
                        color="#000"
                        fontSize="18px"
                        fontWeight={700}
                        lineHeight="120%"
                        sx={{
                            cursor: 'pointer',
                            fontStyle: 'italic',
                        }}
                    >
                        Skip
                    </Text>
                )}
                <Box>
                    <Button
                        height={60}
                        bg="#53F"
                        type={type}
                        onClick={onNextPress}
                        disabled={disabled}
                        sx={{ borderRadius }}
                    >
                        {nextButtonText}
                    </Button>
                </Box>

            </Flex>
        </Flex>
    </Box>
    );
}

export default Footer;
