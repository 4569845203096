import { useDispatch } from 'react-redux';
import React, { useCallback, useMemo, useState } from 'react';

import { ReactComponent as AlertIcon } from './alert.svg';
import { ReactComponent as ReverseIcon } from './reverse.svg';
import { destroyCollection } from '../../api';
import { setCheckmark } from '../../../ui/actions';
import {
  useEntityFromRouteParams,
  useParamsFromRoute,
  useRouteFromEntityParams,
} from '../../hooks';
import { useToggle, useMatch } from '../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { useEntityActionCreators } from '../../../entities/hooks';
import DeleteCollectionModal from './modal';

const DeleteCollection = () => {
  const { push, replace } = useHistory();
  const {
    params: { id, type },
  } = useMatch();
  const route = useRouteFromEntityParams({ type, id });
  const { entity } = useEntityFromRouteParams({ id, type });
  const entityParams = useParamsFromRoute({ type, id });
  const { receiveDeletion } = useEntityActionCreators();

  const dispatch = useDispatch();
  const [loading, toggleLoading] = useToggle();

  const onClose = useCallback(() => replace(route), [replace, route]);

  const [confirming, setConfirming] = useState(false);

  const headerIcon = useMemo(() => (!confirming ? <AlertIcon /> : <ReverseIcon />), [confirming]);
  const bodyTitle = useMemo(
    () => (confirming ? 'This action is not reversible' : `Delete all stories in "${entity.name}"`),
    [confirming, entity.name]
  );

  const bodyText = useMemo(
    () =>
      confirming
        ? 'Are you sure you want to delete the collection and everything in it?'
        : `All stories in "${entity.name}" will be deleted unless they also belong to another collection`,
    [confirming, entity.name]
  );

  const cancelText = useMemo(() => (confirming ? 'Wait, take me back!' : 'Cancel'), [confirming]);
  const destroyText = useMemo(() => (confirming ? 'DELETE NOW' : 'Delete everything now'), [
    confirming,
  ]);

  const onConfirm = useCallback(() => {
    toggleLoading();

    receiveDeletion({ entityType: entityParams.type, id: entityParams.id });
    return destroyCollection(entityParams).then(() => {
      dispatch(setCheckmark({ message: 'Deleted' }));
      push('/');
    });
  }, [dispatch, entityParams, push, receiveDeletion, toggleLoading]);

  const onDestroyClick = useCallback(() => (confirming ? onConfirm() : setConfirming(true)), [
    confirming,
    onConfirm,
  ]);

  return (
    <DeleteCollectionModal
      onClose={onClose}
      headerIcon={headerIcon}
      bodyTitle={bodyTitle}
      bodyText={bodyText}
      onDestroyClick={onDestroyClick}
      destroyText={destroyText}
      onCancelClick={onClose}
      cancelText={cancelText}
      disabled={loading}
    />
  );
};

export default DeleteCollection;
