import React from 'react';
import { Text, Box, Flex } from 'rebass';
import { Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import Footer from './Footer';
import { bodyWithLockVersion } from 'app/form/helper';
import { updateStory } from 'stories/actions';
import TextField from 'app/form/formik/text/index';
import QuestionBar from './QuestionBar';

const Step4 = ({ attributes, storyId, onStepDown, onStepUp, story, percentComplete }) => {
    const dispatch = useDispatch();
    const journalId = story?.['journal-ids'][0]

    return (
        <Flex flexDirection="column" maxWidth={500} m="auto" mt={['10px', '100px']} justifyContent="center" alignItems="left">
            <QuestionBar question={attributes?.question} />
            <Text
                px={3}
                textAlign="left"
                fontFamily='Zilla Slab'
                fontSize={30}
                lineHeight="120%"
                letterSpacing='-0.66px'
                my={3}
            >
                <i style={{ color: 'rgba(22, 22, 29, 0.70)' }}>(Optional)</i><br /> Shall we give this story a name?
            </Text>

            <Formik
                initialValues={{
                    title: story?.title === 'Untitled Story' ? '' : story?.title,
                    id: storyId,
                    'journal-id': journalId,
                }}
                enableReinitialize
                onSubmit={async values => {
                    dispatch(updateStory(storyId, bodyWithLockVersion(story, values))).then(() => {
                        onStepUp()
                    });
                }}
            >
                {({ values, handleSubmit }) => (
                    <Form>
                        <Box px={2}>
                            <Field
                                placeholder="ex. The Summer of ‘69"
                                name="title"
                                showError
                                width='100%'
                                component={TextField}
                            />
                        </Box>

                        <Footer
                            onBack={onStepDown}
                            disabled={!values?.title}
                            onSkipPress={onStepUp}
                            onNextPress={handleSubmit}
                            percentComplete={percentComplete}
                            nextButtonText="Next"
                        />
                    </Form>
                )}
            </Formik>
        </Flex >
    );
};

export default Step4;
